import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { DashpivotEvent, EventNotifierService, EventTypes, List } from '@site-mate/dashpivot-shared-library';

import { FolderSelectionService } from 'app/folders/folder-selection.service';
import { OverlayService } from 'app/secure/overlay.service';
import { SegmentService } from 'app/segment/segment.service';
import { DeploymentModes } from 'app/shared/model/lists/deployment-modes.model';
import { ProjectWeb } from 'app/shared/model/project.model';
import { ProjectService } from 'app/shared/service/project.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';

import { ListsService } from './lists.service';

@Injectable({
  providedIn: 'root',
})
export class ListDeploymentService {
  private readonly listsBeingDeployedSubject = new BehaviorSubject<List[] | null>(null);
  listsBeingDeployed$ = this.listsBeingDeployedSubject.asObservable();

  private readonly activeDeployModeSubject = new BehaviorSubject<DeploymentModes>(null);
  activeDeployMode$ = this.activeDeployModeSubject.asObservable();

  private currentDeployMode: DeploymentModes.Single | DeploymentModes.Bulk | DeploymentModes.Undeploy;

  projects = [] as ProjectWeb[];
  companyId: string;
  deployedParentIds = [] as string[];

  constructor(
    private readonly folderSelectionService: FolderSelectionService,
    private readonly overlayService: OverlayService,
    private readonly toastrService: ToastrService,
    private readonly projectsService: ProjectService,
    private readonly listsService: ListsService,
    private readonly teamService: TeamService,
    private readonly segmentService: SegmentService,
  ) {
    this.overlayService.overlayClicked$.subscribe(() => {
      this.stopDeployMode();
    });
  }

  startDeployMode(listIds: string[]) {
    this.getListsAndUpdate(listIds).subscribe((lists) => {
      return lists.length === 1 ? this.startSingleListDeployment() : this.startBulkListDeployment();
    });
  }

  startUndeployMode(listIds: string[]) {
    this.getListsAndUpdate(listIds).subscribe(() => {
      this.overlayService.showOverlay();
      this.activeDeployModeSubject.next(DeploymentModes.BulkUndeploy);
      this.currentDeployMode = DeploymentModes.Undeploy;
    });
  }

  private getListsAndUpdate(listIds: string[]) {
    this.companyId = this.teamService.getCurrentTeam().id;

    return this.projectsService.getCompanyProjects(this.companyId).pipe(
      switchMap((projects) => {
        this.projects = projects as ProjectWeb[];
        return forkJoin(listIds.map((listId) => this.listsService.getList(listId)));
      }),
      tap((lists) => {
        this.listsBeingDeployedSubject.next(lists);
      }),
    );
  }

  private startSingleListDeployment() {
    const [list] = this.listsBeingDeployedSubject.value;

    const deployedProjects = this.projects.filter((project) => list.deployedParents.includes(project.id));
    this.folderSelectionService.deselectAllFolders();
    this.folderSelectionService.selectFolders(deployedProjects);
    this.overlayService.showOverlay();
    this.activeDeployModeSubject.next(DeploymentModes.Management);
    this.currentDeployMode = DeploymentModes.Single;
  }

  private startBulkListDeployment() {
    this.overlayService.showOverlay();
    this.activeDeployModeSubject.next(DeploymentModes.BulkDeploy);
    this.currentDeployMode = DeploymentModes.Bulk;
  }

  stopDeployMode() {
    this.overlayService.hideOverlay();
    this.folderSelectionService.deselectAllFolders();
    this.listsBeingDeployedSubject.next(null);
    this.activeDeployModeSubject.next(null);
  }

  applyDeployedChanges() {
    const deployFromMode = {
      [DeploymentModes.Single]: this.manageSingleList,
      [DeploymentModes.Bulk]: this.bulkDeploy,
      [DeploymentModes.Undeploy]: this.bulkUndeploy,
    };

    deployFromMode[this.currentDeployMode]?.call(this);

    this.folderSelectionService.deselectAllFolders();
    this.stopDeployMode();
  }

  private manageSingleList() {
    const [list] = this.listsBeingDeployedSubject.value;
    const alreadyDeployed = new Set(list.deployedParents || []);
    const selected = new Set(this.folderSelectionService.selectedFoldersIds);

    this.listsService
      .updateGlobalItemCompanyList(this.companyId, list.id, {
        deployedParents: this.folderSelectionService.selectedFoldersIds,
      })
      .pipe(catchError(() => this.handleToastError('listDeployManagementFailed')))
      .subscribe(() => {
        this.toastrService.successByKey('listDeployManagementSuccessful');
        const undeployedAtLeastOne = [...alreadyDeployed].some((id) => !selected.has(id));
        const deployedAtLeastOne = [...selected].some((id) => !alreadyDeployed.has(id));

        if (undeployedAtLeastOne) {
          this.notifyEvent(EventTypes.ListUndeployed, DeploymentModes.Single);
        }
        if (deployedAtLeastOne) {
          this.notifyEvent(EventTypes.ListDeployed, DeploymentModes.Single);
        }
      });
  }

  private bulkDeploy() {
    this.deployedParentIds = this.folderSelectionService.selectedFoldersIds;

    const mappedLists$ = this.getMappedLists$(true);
    forkJoin(mappedLists$)
      .pipe(catchError(() => this.handleToastError('listsBulkDeployFailed')))
      .subscribe(() => {
        this.toastrService.successByKey('listsBulkDeploySuccessful');
        this.notifyEvent(EventTypes.ListDeployed, DeploymentModes.Bulk);
      });
  }

  private bulkUndeploy() {
    this.deployedParentIds = this.folderSelectionService.selectedFoldersIds;

    const mappedLists$ = this.getMappedLists$();

    forkJoin(mappedLists$)
      .pipe(catchError(() => this.handleToastError('listsBulkUndeployFailed')))
      .subscribe(() => {
        this.toastrService.successByKey('listsBulkUndeploySuccessful');
        this.notifyEvent(EventTypes.ListUndeployed, DeploymentModes.Bulk);
      });
  }

  private notifyEvent(event: EventTypes, Context: DeploymentModes.Single | DeploymentModes.Bulk) {
    void EventNotifierService.notify(new DashpivotEvent(event, { Context }), this.segmentService);
  }

  private getMappedLists$(concatParentIds = false) {
    return this.listsBeingDeployedSubject.value.map((list) => {
      let deployedParents = (list.deployedParents || []).filter(
        (parentId) => !this.deployedParentIds.includes(parentId),
      );
      if (concatParentIds) {
        deployedParents = deployedParents.concat(...this.deployedParentIds);
      }
      return this.listsService.updateGlobalItemCompanyList(this.companyId, list.id, { deployedParents });
    });
  }

  private handleToastError(error: string): Observable<null> {
    this.toastrService.errorByKey(error);
    return throwError(() => new Error(error));
  }
}
